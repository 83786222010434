/**
 * Checks if the element specified is visible in the viewport.
 * @param el The element to check.
 * @param partiallyVisible If true, the element will be considered visible if it's only partially visible in the viewport.
 * @returns true if the element is visible in the viewport, false otherwise.
 */
const elementIsVisibleInViewport = (el: Element, partiallyVisible = false) => {
  const { top, left, bottom, right } = el.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top >= 0 && top < innerHeight) || (bottom > 0 && bottom <= innerHeight)) &&
        ((left >= 0 && left < innerWidth) || (right > 0 && right <= innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export default elementIsVisibleInViewport;
